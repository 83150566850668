<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wrapp-section-title section-title-center">
            <div class="section-subtitle">we build our solutions with</div>
            <h2 class="section-title">{{title}}</h2>
          </div>
          <div class="content mb-5">
            <div class="img-style">
              <img src="/img/objectpower/umbraco.jpg" class="m-auto"  alt />
            </div>
          </div>
        </div>
        <div class="col">
          <p>Umbraco is a fully-featured open source content management system with the flexibility to run anything from small campaign or brochure sites right through to complex applications for Fortune 500's and some of the largest media sites in the world.</p>         
        </div>
      </div>

    
    </div>
  </section>
</template>

<script>
export default {
  name: "Umbraco",
  data() {
    return {
      title: "Umbraco"
    };
  }
};
</script>

<style>
</style>